/* IMPORT CUSTOM FONT */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-white;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  @apply mx-1 rounded-md border border-white bg-transparent;
}

option {
  @apply text-black;
}

.control-label {
  @apply capitalize;
}

.required {
  @apply text-red-600;
}

.control-label {
  @apply mb-1;
}

.modal-content .control-label {
  @apply mb-4;
}

.modal-content .form-group {
  @apply mb-4;
}

.characterisation-panel .control-label {
  @apply block text-base;
}

.field-description {
  @apply text-base text-gray-500;
}

.characterisation-panel .field-description {
  @apply text-xs;
}

.characterisation-panel .form-control {
  @apply ml-0 min-w-[75%] py-0 text-sm;
}

.characterisation-panel .form-group {
  @apply mb-2 text-sm;
}

/* Multiline radio */
.field-radio-group:not(:has(.radio-inline)) {
  @apply flex flex-col gap-1;
}

.field-radio-group > .radio label > span {
  @apply flex items-center gap-1;
}

.form-group > .checkbox label {
  @apply mx-1 flex items-center gap-1;
}

.form-group > .checkboxes .checkbox label span {
  @apply flex items-center gap-1;
}

.radio.disabled {
  @apply flex items-center;
}

.radio.disabled input[type='radio']:checked {
  @apply m-0 appearance-none before:block before:h-3 before:w-3 before:rounded-full before:border-2 before:border-solid before:border-blue-400 before:content-[''];
}

/* Inline radio */
label.radio-inline input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

label.radio-inline {
  @apply mx-1;
  display: inline-block;
  padding: 0.1em 0.5em;
  border-radius: 8px;
}

fieldset label.radio-inline {
  background: none;
  border: 2px solid rgba(58, 63, 153);
}

label.radio-inline:first-child {
  @apply ml-0;
}

label.radio-inline:last-child {
  @apply mr-0;
}

label.radio-inline:hover {
  cursor: pointer;
}

fieldset label.radio-inline:hover {
  border-color: rgb(90, 204, 230);
}

/* prettier-ignore */
fieldset label.radio-inline:has(input[type="radio"]:checked) {
  border-color: rgb(90, 204, 230);
}

.user-preferences {
  /* G3D-108: there are many classes about length on the modal component
  As this is a common component, we have to set a specific class
  and a CSS with `important` */
  @apply w-[80vw] !important;
}
